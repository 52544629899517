import React from 'react';
import { useHistory } from 'react-router';
import Comparte from '../pages/Comparte';

const ComparteContainer = () =>{
    const history = useHistory();

    return(
        <Comparte history={history} />
    )
};

export default ComparteContainer;