import React from "react";
import MenuCM from "../components/others/MenuCM";
import CM from "../images/casamayor.png";

const Promocion = ({ history }) => (
  <div className="contenedor fondo-quinielas">
    <button onClick={() => history.goBack()} className="boton-regresar">
      <i className="fas fa-chevron-left" />
    </button>
    <MenuCM name="promocion" />
    <div className="container">
      <div className="text-center">
        <img src={CM} className="imagen-logo" alt="Letras Casa Mayor" />
      </div>
      <div className="text-center">
        <h4>Promociones</h4>
      </div>
      <div className="mt-4 table-promocion table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td className="fila text-center">1 Quiniela</td>
              <td className="fila text-center">x $30</td>
            </tr>
            <tr>
              <td className="fila text-center">10 Quinielas</td>
              <td className="fila text-center">x $270</td>
            </tr>
            <tr>
              <td className="fila text-center">20 Quinielas</td>
              <td className="fila text-center">x $500</td>
            </tr>
            <tr>
              <td className="fila text-center">50 Quinielas</td>
              <td className="fila text-center">x $1,200</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-center">
        <p>*Promoción válida al canjearse en una sola quiniela</p>
      </div>
    </div>
  </div>
);

export default Promocion;
