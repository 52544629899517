import React from 'react';
import Clave from '../components/modals/Clave';

const Usuario = ({
    usuarios,
    onCambiarClave,
    usuario,
    modal,
    ocultar,
    bloquear
}) => (
    <div className="principal pt-5">
        <Clave show={modal} handleClose={ocultar} usuario={usuario} />
        <div className="container">
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Usuario</th>
                            <th scope="col">Whatsapp</th>
                            <th scope="col">Fecha activo</th>
                            <th scope="col">Contraseña</th>
                            <th scope="col">Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            usuarios.map(
                                (usuario, i) => (
                                    <tr key={i}>
                                        <th>{i + 1}.</th>
                                        <th>{usuario.usuario}</th>
                                        <th>{usuario.whatsapp}</th>
                                        <th>{usuario.fechaActivo}</th>
                                        <th><button onClick={() => onCambiarClave(usuario)} className="btn btn-info">Cambiar clave</button></th>
                                        {usuario.activo ?
                                            <th><button name={ usuario.whatsapp } onClick={ bloquear} className="btn btn-danger">Bloquear</button></th>
                                            :
                                            <th><button name={ usuario.whatsapp } onClick={ bloquear} className="btn btn-success">Desbloquear</button></th>
                                        }
                                    </tr>
                                )
                            )
                        }
                    </tbody>
                </table>

            </div>

        </div>
    </div>
);

export default Usuario;