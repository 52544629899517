import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Request from "../async/request";
import Juego from "../pages/Juego";

const JuegoContainer = () => {
  const [juegos, setJuegos] = useState([]);
  const [jornada, setJornada] = useState("");
  const [juego, setJuego] = useState("");
  const [quinielas, setQuinielas] = useState([]);
  const [cargando, setCargando] = useState(false);
  const history = useHistory();
  const [body, setBody] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    async function cargar() {
      let request = new Request("GET", null, 0, "/control/jornada/activa");
      let response = await request.exec();
      if (response.isSuccess) {
        if (response.data.length > 0) {
          setJornada(response.data[0].jornada);
          request = new Request(
            "GET",
            null,
            0,
            "/control/partido/clave/" + response.data[0].clave
          );
          response = await request.exec();
          if (response.isSuccess) {
            setJuegos(response.data);
            let items = response.data.length;
            let nuevo = "";
            for (let i = 0; i < items; i++) {
              nuevo += "-";
            }
            setJuego(nuevo);
            request = new Request("GET", null, 0, "/control/equipo");
            response = await request.exec();
            setTeams(response.data);
          }
        } else {
          setTitle("¡Ah no bueno!");
          setBody(
            "Por el momento no hay ninguna Quiniela disponible. En la sección de Quinielas podrás conocer las próximas Quinielas a jugarse y cuando estarán activas"
          );
          setShow(true);
        }
      }
    }
    cargar();
  }, [history]);

  const onChange = (option, index) => {
    index -= 1;
    let nuevo = juego.substring(0, index) + option + juego.substring(index + 1);
    setJuego(nuevo);
  };

  const limpiar = () => {
    document.getElementById("form-match").reset();
    let newGame = "";
    for (let i = 0; i < juegos.length; i++) {
      newGame += "-";
    }
    setJuego(newGame);
  };

  const agregar = () => {
    if (!juego.includes("-")) {
      setQuinielas([...quinielas, juego]);
      setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 1000);
      limpiar();
    } else {
      setTitle("Debes llenar todos los juegos");
      setBody(
        "Para poder añadir la Quiniela debes completar todos los partidos"
      );
      setShow(true);
    }
  };

  const aleatorio = () => {
    let options = ["L", "E", "V"];
    var min = 0;
    var max = 3;
    var index = 0;
    let id = "";
    limpiar();
    let newGame = juego;
    for (let i = 0; i < juegos.length; i++) {
      index = parseInt(Math.random() * (+max - +min) + +min);
      id = options[index] + "" + (i + 1);
      document.getElementById(id).checked = true;
      newGame =
        newGame.substring(0, i) + options[index] + newGame.substring(i + 1);
    }
    setJuego(newGame);
  };

  const borrar = (e) => {
    setQuinielas(quinielas.filter((item) => item !== e.target.id));
  };

  const onClose = () => {
    setShow(false);
  };

  const regresar = () => history.goBack();

  const enviar = async (e) => {
    setCargando(true);
    let array = "";
    if (quinielas.length > 0) {
      quinielas.forEach((juego, i) => {
        let myJuego = "";
        for (let i = 0; i < juego.length; i++) {
          myJuego += ` ${juego[i]}`;
        }
        console.log(myJuego);
        array = array + "%0A" + (i + 1) + "." + myJuego;
      });
      window.open(
        "https://api.whatsapp.com/send/?phone=5215586688702&text=" +
          jornada +
          "%0A" +
          String(array) +
          "%0A%0A" +
          name.toUpperCase()
      );
    } else {
      setTitle("Debes agregar al menos una quiniela para poder jugar");
      setShow(true);
    }
    setTimeout(() => setCargando(false), 1000);
  };

  const onName = ({ target }) => {
    setName(target.value);
  };

  return (
    <Juego
      aleatorio={aleatorio}
      agregar={agregar}
      limpiar={limpiar}
      onChange={onChange}
      juegos={juegos}
      juego={juego}
      jornada={jornada}
      body={body}
      title={title}
      show={show}
      quinielas={quinielas}
      onClose={onClose}
      borrar={borrar}
      regresar={regresar}
      enviar={enviar}
      cargando={cargando}
      name={name}
      onName={onName}
      teams={teams}
    />
  );
};

export default JuegoContainer;
