import React from 'react';
import { useHistory } from 'react-router';
import Como from '../pages/Como';

const ComoContainer = () =>{
    const history = useHistory();

    return(
        <Como 
            regresar={() => history.goBack()}
        />
    )
};

export default ComoContainer;