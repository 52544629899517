import "bootstrap/dist/css/bootstrap.css";
import dayjs from "dayjs";
import locale from "dayjs/locale/es";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import ReactDOM from "react-dom";
import App from "./route/App";
import "./styles/fonts.css";
import "./styles/modal.css";
import "./styles/new.css";
import "./styles/styles.css";
import "./styles/theme.css";

const container = document.getElementById("root");

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.locale(locale);

ReactDOM.render(<App />, container);
