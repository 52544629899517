import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const CardQuiniela = ({
    jornada,
    verJornada,
    editable = false
}) =>{
    const [open, setOpen] = useState(false);
    const history = useHistory();

    useEffect( ()=>{
        let now = new Date();
        let inicio = jornada.inicio.replace(/[ :]/g, "-").split("-");
        let fin = jornada.fin.replace(/[ :]/g, "-").split("-");
        let i = new Date( inicio[0], inicio[1] - 1, inicio[2], inicio[3], inicio[4], inicio[5] );
        let j = new Date( fin[0], fin[1] - 1, fin[2], fin[3], fin[4], fin[5] );

        if(i <= now && j >= now )
            setOpen(true);

    },[jornada]);

    const getMesFull = (i) =>{
        let res;
        switch(i){
            case 0:  res = "Enero"; break;
            case 1:  res = "Febrero"; break;
            case 2:  res = "Marzo"; break;
            case 3:  res = "Abril"; break;
            case 4:  res = "Mayo"; break;
            case 5:  res = "Junio"; break;
            case 6:  res = "Julio"; break;
            case 7:  res = "Agosto"; break;
            case 8:  res = "Septiembre"; break;
            case 9:  res = "Octubre"; break;
            case 10: res =  "Noviembre"; break;
            case 11: res =  "Diciembre"; break;
            default: res = "NaN";
        }
        return res;
    }

    const getFormato = (inicio, fin) =>{
        inicio = inicio.replace(/[ :]/g, "-").split("-");
        fin = fin.replace(/[ :]/g, "-").split("-");
        let i = new Date( inicio[0], inicio[1] - 1, inicio[2], inicio[3], inicio[4], inicio[5] );
        let j = new Date( fin[0], fin[1] - 1, fin[2], fin[3], fin[4], fin[5] );
        let mensaje;
        if( i.getMonth() === j.getMonth() ){
            let mes = getMesFull( i.getMonth() );
            mensaje = "Disponible del " + i.getDate() + " al " + j.getDate() + " de " + mes; 
        }
        else{
            let mes = getMesFull( i.getMonth() );
            let mes2 = getMesFull( j.getMonth() );
            mensaje = "Disponible del " + i.getDate() + " de " + mes + " al " + j.getDate() + " de " + mes2;
        }
        return mensaje;
    }

    return(
        <div className="evento mt-3">
            <div onClick={() => verJornada(jornada)} className="w-20 cursor-click  d-flex flex-column">
                <div className="dia">{ jornada.clave }</div>
            </div>
            <div className="w-80 d-flex flex-row justify-content-between content-card">
                <div>    
                    <p className="titulo-quiniela">{jornada.jornada}</p>
                    <p className="descripcion-quiniela">{getFormato( jornada.inicio, jornada.fin )}</p>
                </div>
                {open ?
                    <div className='d-flex align-items-center pe-3'>
                        <button onClick={() => history.push("/jugar")} className='btn btn-primary'>Jugar</button>
                    </div>
                    :
                    void(0)
                }
            </div>
        </div>
    );
}
export default CardQuiniela;