import React, { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import Logo from '../images/casamayor.png';

const Login = ({ onChange, form, handleSubmit, msg, loading, regresar, viewPassword }) => (
    <Fragment>
        <div className="contenedor">
            <div className="contenedor">
                <div className="text-center">
                    <img src={Logo} alt="" className="img-logo rotating" />
                    <h5>Iniciar Sesión</h5>
                </div>
                <div className="container-game">
                    <form onSubmit={handleSubmit} className="form-game">
                        <div className="form-group">
                            <label htmlFor="id" className="l-control mb-2">Whatsapp:</label>
                            <input onChange={onChange} value={form.whatsapp} type="phone" autoComplete="off" required id="id" className="form-control"></input>
                        </div>
                        <div className="mt-2 form-group">
                            <label htmlFor="clave" className="l-control mb-2">Contraseña:</label>
                            <input onChange={onChange} value={form.clave} type="password" autoComplete="off" required id="clave" className="password form-control"></input>
                            <i onClick={viewPassword} className="hidden-password fas fa-eye"></i>
                        </div>
                        <div className="text-rigth mt-3">
                            <p>{msg}</p>
                        </div>
                        <div className="d-flex justify-content-end pb-4">
                            {loading ?
                                <div className="pad-5">
                                    <Spinner className="mt-2" animation="grow" />
                                </div>
                                :
                                <button className="mt-2 btn-alternative-cm">
                                    Ingresar
                                </button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Fragment>

);

export default Login;