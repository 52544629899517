import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Logo from "../others/Logo";

export const CardTransmision = ({ teams, item }) => {
  const [info, setInfo] = useState([{}, {}]);
  const [img, setImg] = useState("");

  useEffect(() => {
    let array = [{}, {}];
    teams.forEach((element) => {
      if (element.clave === item.local) array[0] = element;
      else if (element.clave === item.visita) array[1] = element;
    });
    setInfo(array);
  }, [item, teams]);

  useEffect(() => {
    const imgs = [
      ["", "https://casamayor.click/tvs/tudn.png"],
      ["TUDN", "https://casamayor.click/tvs/tudn.png"],
      ["Canal 5", "https://casamayor.click/tvs/cinco.png"],
      ["Las Estrellas", "https://casamayor.click/tvs/estrellas.png"],
      ["Azteca", "https://casamayor.click/tvs/azteca.png"],
      ["Sky", "https://casamayor.click/tvs/sky.png"],
      ["Afizzionados", "https://casamayor.click/tvs/izzi.png"],
      ["Vix", "https://casamayor.click/tvs/vix.png"],
      ["Fox", "https://casamayor.click/tvs/FOX.png"],
      ["Fox Premium", "https://casamayor.click/tvs/FOXP.png"],
      ["ESPN", "https://casamayor.click/tvs/ESPN.png"],
      ["HBO", "https://casamayor.click/tvs/hbo.png"],
      ["APPLE", "https://casamayor.click/tvs/APPLE.png"],
      ["PRIME", "https://casamayor.click/tvs/PRIME.png"],
      ["Claro Video", "https://casamayor.click/tvs/claro.png"],
      ["Star+", "https://casamayor.click/tvs/start.png"],
      ["Paramount", "https://casamayor.click/tvs/paramount.png"],
    ];
    imgs.forEach((value) => {
      if (value[0] === item.transmision) {
        setImg(value[1]);
      }
    });
  }, [item.transmision]);

  return (
    <div className="card-match-flayer">
      <div className="name-team-transmision text-r">
        <p className="name-team-transmision ">{info[0].nombre}</p>
      </div>
      <div id="transmision" className="flayer-local">
        <Logo team={info[0]} />
      </div>
      <div className="transmision-options">
        <div>
          <img className="logo-transmision" src={img} alt="logo transmision" />
        </div>
        <p className="text-fecha p-0 m-0">
          {dayjs(item.fecha).isBefore("2023-01-10")
            ? "POR DEFINIR"
            : dayjs(item.fecha).format("hh:mm a")}
        </p>
        <p className="text-dia m-0 p-0">{dayjs(item.fecha).format("dddd")}</p>
      </div>
      <div id="transmision" className="flayer-visita">
        <Logo team={info[1]} />
      </div>
      <div className="name-team-transmision">
        <p className="name-team-transmision ps-2">{info[1].nombre}</p>
      </div>
    </div>
  );
};
