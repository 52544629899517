import React, { Fragment } from "react";
import CardQuiniela from "../components/cards/CardQuiniela";
import MenuCM from "../components/others/MenuCM";
import Jornada from "../components/modals/Jornada";

const Proxima = ({
  modal,
  verJornada,
  cerrar,
  regresar,
  jornadas,
  partidos,
  teams,
}) => (
  <Fragment>
    <MenuCM name="proxima" />
    <Jornada
      teams={teams}
      partidos={partidos}
      show={modal.show}
      handleClose={cerrar}
      title={modal.title}
    />
    <div className="contenedor fondo-resultados">
      <button onClick={regresar} className="boton-regresar">
        <i className="fas fa-chevron-left" />
      </button>
      <div className="text-center mt-3">
        <h1>Próximas Quinielas</h1>
      </div>
      <div className="contenedor-calendario mt-4">
        {jornadas.length > 0 ? (
          jornadas.map((jornada, i) => (
            <CardQuiniela jornada={jornada} key={i} verJornada={verJornada} />
          ))
        ) : (
          <h5 className="text-center mt-5">
            No hay Quinielas disponibles por ahora
          </h5>
        )}
      </div>
    </div>
  </Fragment>
);

export default Proxima;
