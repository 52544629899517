import React from "react";
import LogoRes from "../others/LogoRes";

export const CardResultado = ({ item }) => {
  return (
    <div className="card-match-flayer">
      <div className="name-team-transmision text-r">
        <p className="name-team-transmision ">{item.nombreLocal}</p>
      </div>
      <div id="transmision" className="flayer-local">
        <LogoRes team={item.local} />
      </div>
      <div className="resultado-options">
        <div className="resultado-sq">{item.rl}</div>
        <div className="resultado-line">
          <div className="resultado-line-body"></div>
        </div>
        <div className="resultado-sq">{item.rv}</div>
      </div>
      <div id="transmision" className="flayer-visita">
        <LogoRes team={item.visita} />
      </div>
      <div className="name-team-transmision">
        <p className="name-team-transmision ps-2">{item.nombreVisita}</p>
      </div>
    </div>
  );
};
