import React from "react";

const Redes = () => (
  <div className="contenedor-redes">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://api.whatsapp.com/send/?phone=5215586688702&text=Hola!!+Me+gustaría+jugar+en+la+Quiniela+de+Casa+Mayor+⚽💸🏆&app_absent=0"
    >
      <i className="fab fa-whatsapp text-second icono-cm" />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://instagram.com/casamayormx"
    >
      <i className="fab fa-instagram text-second icono-cm"></i>
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.facebook.com/casamayormx"
    >
      <i className="fab fa-facebook text-second icono-cm" />
    </a>
  </div>
);

export default Redes;
