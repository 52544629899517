import React, { Fragment } from 'react';
import MenuCM from '../components/others/MenuCM';

const Comparte = ({ history }) => (
    <Fragment>
        <MenuCM name="comparte" />
        <div className="contenedor fondo-menu">
            <button onClick={() => history.goBack()} className="boton-regresar">
                <i className="fas fa-chevron-left" />
            </button>
            <div className="container">
                <div className="contenedor-centrado">
                    <h5 className="text-center mt-4 mb-4">Tu código para invitar a tus amigos es tu número de whatsapp</h5>
                    <div className="codigo d-flex align-items-center justify-content-center flex-row">
                        <div className="">
                            <i className="icon-share fas fa-share-alt" />
                        </div>
                        <div className="">
                            MIWHATSAPP
                        </div>
                    </div>
                </div>
                <h5 className="text-center mt-4 mb-2">¿Cómo funciona?</h5>
                <div className="mt-3 text-center">Después de invitar a 5 amigos y que jueguen su primer Quiniela recibirás una Quiniela completamente GRATIS.</div>
                <h5 className="text-center mt-4 mb-2">¿Qué deben hacer mis invitados?</h5>
                <div className="mt-3 text-center">Cuando manden su Quiniela deben comentarnos que son referidos de un usuario y compartirnos tu número de whatsapp para ir contando a tus invitados.</div>
                <h5 className="text-center mt-4 mb-2">¿Cuántas veces puedo aplicar la promoción?</h5>
                <div className="mt-3 text-center">Puedes aplicar la promoción así como tantos amigos invites, no olvides recordarles que en su registro ingresen TU código de invitación para saber que son referidos tuyos.</div>
            </div>
        </div>
    </Fragment>
);

export default Comparte;