import React from 'react';
import { useHistory } from 'react-router';

const MenuCM = ({name}) =>{
    const history = useHistory();

    const cambiar = (e) => history.push("/" + e.target.id );

    return(
        <div className="menu-casamayor">
            <div onClick={cambiar} id="" className={"menu-boton " + (name === "inicio" ? "activo" : "") }>
                <i className="fas fa-home" />
                <p>Inicio</p>
            </div>
            <div onClick={cambiar} id="proxima" className={"menu-boton " + (name === "proxima" ? "activo" : "") }>
                <i className="fas fa-calendar-alt" />
                <p>Quinielas</p>
            </div>
            <div onClick={cambiar} id="promocion" className={"menu-boton " + (name === "promocion" ? "activo" : "") }>
                <i className="fas fa-tag" />
                <p>Promociones</p>
            </div>
            <div onClick={cambiar} id="pago" className={"menu-boton " + (name === "pago" ? "activo" : "") }>
                <i className="fas fa-dollar-sign"></i>
                <p>Pago</p>
            </div>
            <div onClick={cambiar} id="menu" className={"menu-boton " + (name === "menu" ? "activo" : "") }>
                <i className="fas fa-bars" />
                <p>Menú</p>
            </div>
        </div>
    )
};

export default MenuCM;