import React from 'react';
import { useHistory } from 'react-router';
import Afiliados from '../pages/Afiliados';

const AfiliadosContainer = () =>{
    const history = useHistory();

    return(
        <Afiliados
            regresar = { () => history.goBack()}
        />
    )
};

export default AfiliadosContainer;