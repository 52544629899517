import React from 'react';
import { useHistory } from 'react-router';
import Casa from '../pages/Casa';

const CasaContainer = () =>{
    const history = useHistory();

    return(
        <Casa 
            regresar={() => history.goBack() }
        />
    )
};

export default CasaContainer;