import React from 'react';
import { useHistory } from 'react-router';
import Promocion from '../pages/Promocion';

const PromocionContainer = () =>{
    
    return(
        <Promocion history={useHistory} />
    )
};

export default PromocionContainer;