import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Request from '../async/request';
import Proxima from '../pages/Proxima';

const ProximaContainer = () =>{
    const history = useHistory();
    const [modal, setModal] = useState({show:false, title:'', body:''});
    const [jornadas, setJornadas] = useState([]);
    const [partidos, setPartidos] = useState([]);
    const [teams, setTeams] = useState([]);

    useEffect(
        () =>{
            async function get(){
                let request = new Request("GET", null, 0, "/control/jornada");
                let response = await request.exec();
                if( response.isSuccess )
                    setJornadas( response.data ); 
                request = new Request("GET", null, 0, "/control/equipo");
                response = await request.exec();
                if( response.isSuccess )
                    setTeams( response.data ); 
            }
            get();
        },[]
    )
    
    const verJornada = async (jornada) =>{
        let request = new Request("GET", null, 1, "/control/partido/clave/" + jornada.clave );
        let response = await request.exec();
        if( response.isSuccess ){
            setPartidos( response.data );
            setModal({...modal, show:true, title: jornada.jornada });
        }
    }

    const cerrar = () => setModal({...modal, show:false});

    const regresar = () => history.goBack();

    return(
        <Proxima 
            jornadas = { jornadas }
            verJornada = { verJornada } 
            modal = { modal } 
            regresar = { regresar } 
            cerrar = { cerrar }
            partidos = { partidos }
            teams = { teams }
        />
    )
};

export default ProximaContainer;