import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Request from "../async/request";
import Login from "../pages/Login";

const LoginContainer = () => {
  const [form, setForm] = useState({ id: "", clave: "" });
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let auth = sessionStorage.getItem("auth");
    if (auth !== null) {
      history.push("/jornada");
    }
  }, [history]);

  const onChange = (e) => setForm({ ...form, [e.target.id]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let request = new Request("POST", form, 0, "/service/autentica");
    let response = await request.exec();
    if (response.isSuccess) {
      sessionStorage.setItem("auth", response.data);
      request = new Request("GET", null, 1, "/control/usuario/find/" + form.id);
      response = await request.exec();
      sessionStorage.setItem("user", JSON.stringify(response.data[0]));
      setLoading(false);
      history.push("/jornada");
    } else {
      setLoading(false);
      setMsg(response.message);
    }
  };

  const viewPassword = () => {
    var element = document.getElementById("clave");
    if (element.getAttribute("type") === "password")
      element.setAttribute("type", "text");
    else element.setAttribute("type", "password");
  };

  return (
    <Login
      viewPassword={viewPassword}
      loading={loading}
      msg={msg}
      onChange={onChange}
      form={form}
      handleSubmit={handleSubmit}
    />
  );
};

export default LoginContainer;
