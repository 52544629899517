import React, { Fragment } from 'react';
import MenuCM from '../components/others/MenuCM';
import logo from '../images/casamayor.png';
import como from '../images/como.jpg';

const Afiliados = ({regresar}) => (
    <Fragment>
        <MenuCM name="menu" />
        <div className="contenedor p-3 fondo-quinielas">
            <button onClick={regresar} className="boton-regresar">
                <i className="fas fa-chevron-left" />
            </button>
            <div className="text-center pt-2">
                <img src={logo} alt="cuentas" className="img-logo" />
            </div>
            <div className="container mb-5 mt-4">
                <img src={como} alt="cuentas" className="img-fluid mb-4 rounded-5" />
            </div>
        </div>
    </Fragment>
);

export default Afiliados;