import React, {Fragment, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import Request from '../../async/request';

const NuevoEquipo = ({show, handleClose, ligas}) =>{
    const [form, setForm] = useState({
        nombre:'',
        clave:'',
        liga:'',
        color1:'#000000',
        color2:'#ffffff'
    });

    const onChange = ({target}) =>{ 
        setForm({...form, [target.id]: target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let body = { equipo: {...form}};
        let request = new Request("POST", body, 1, "/control/equipo");
        let response = await request.exec();
        let icon = response.isSuccess ? 'success' : 'error';
        let title = response.isSuccess ? 'Equipo creado' : 'Error al crear el equipo';
        setForm({
            nombre:'',
            clave:'',
            liga:'',
            color1:'#000000',
            color2:'#ffffff'
        });
        Swal.fire({
            toast: true,
            icon: icon,
            title: title,
            position: 'top',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true
        });
    }

    return(
        <Fragment>
            <Modal show={show} onHide={handleClose} backdrop="static"  aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex flex-row justify-content-center">
                            <div className="d-flex flag-container">
                                <div className="color1" style={{backgroundColor: form.color1}} />
                                <div className="color2" style={{backgroundColor: form.color2}} />
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <h5>{form.nombre ? form.nombre : "Nombre equipo"}</h5>
                            <p>{form.clave ? form.clave : "clave"}</p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-8 mt-3">
                                <div className="form-group">
                                    <label className="l-control mb-2" htmlFor="nombre">Nombre</label>
                                    <input required id="nombre" value={form.nombre} onChange={onChange} className="form-control" type="text" />
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="form-group">
                                    <label className="l-control mb-2" htmlFor="clave">Clave</label>
                                    <input maxLength="4" value={form.clave} onChange={onChange} required id="clave" className="form-control" type="text" />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="form-group">
                                    <label className="l-control mb-2" htmlFor="liga">Liga</label>
                                    <select onChange={onChange} value={form.liga} required id="liga" className="form-select">
                                        {
                                            ligas.map(
                                                (item, i) => <option key={i}>{item}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3 mt-3">
                                <div className="form-group">
                                    <label className="l-control mb-2" htmlFor="color1">Color 1</label>
                                    <input required id="color1" value={form.color1} onChange={onChange} className="form-control" type="color" />
                                </div>
                            </div>
                            <div className="col-md-3 mt-3">
                                <div className="form-group">
                                    <label className="l-control mb-2" htmlFor="color2">Color 2</label>
                                    <input required id="color2" value={form.color2} onChange={onChange} className="form-control" type="color" />
                                </div>
                            </div>
                        </div>
                        <div className="text-end mt-4">                            
                            <button className="boton-cm m-2" onClick={handleClose}>Cancelar</button>
                            <button className="boton-cm">Crear Equipo</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
} 

export default NuevoEquipo;