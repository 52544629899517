import React, { Fragment } from "react";
import MenuCM from "../components/others/MenuCM";
import pago from "../images/pago.jpg";

const Pago = () => (
  <Fragment>
    <MenuCM name="pago" />
    <div className="contenedor p-3 fondo-azteca-1">
      <div className="text-center pt-5" />
      <div className="container mb-5 mt-4">
        <img src={pago} alt="cuentas" className="img-fluid mb-4 rounded-5" />
      </div>
    </div>
  </Fragment>
);

export default Pago;
