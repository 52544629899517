import html2canvas from "html2canvas";
import { CardResultado } from "../components/cards/CardResultado";
export const Resultado = ({ juegos, teams, jornada }) => {
  const onExport = async () => {
    const doc = document.getElementById("layout-resultado");
    const val = await html2canvas(doc, {
      useCORS: true,
      allowTaint: true,
      onrendered: (canvas) => {
        const data = canvas.toDataURL("image/png");
        var link = document.createElement("a");
        link.download = "layout-resultado.png";
        link.href = data;
        link.click();
      },
    });
    const data = val.toDataURL("image/png");
    var link = document.createElement("a");
    link.download = "layout-resultado.png";
    link.href = data;
    link.click();
  };
  return (
    <>
      <div className="v-center bg-linear">
        <div id="layout-resultado" className="transmision-container">
          <div className="logo-cm-transmision">
            <div className="text-center title-flayer-wa m-0">
              Envía tus quinielas por whatsapp
              <p className="text-wa">
                <span>
                  <i className="fab fa-whatsapp" />
                </span>
                5586688702
              </p>
            </div>
          </div>
          <div className="text-l-transmision">
            <img
              src="https://casamayor.click/static/media/casamayor.4bc1f56c32c281089d9b.png"
              alt="Logo CM"
              className="logo-cm-transmision-img"
            />
          </div>
          <h1 className="mt-3 text-center title-flayer title-flayer-size">
            Resultados
          </h1>
          <h1 className="mt-3 text-center title-flayer title-flayer-size">
            {jornada ? jornada.jornada : ""}
          </h1>
          <div className="transmision-container-body ">
            <div>
              {juegos.map((item, i) => (
                <CardResultado key={i} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="button-export">
        <button className="btn btn-primary" onClick={onExport}>
          Guardar
        </button>
      </div>
    </>
  );
};
